<template>
  <q-form ref="editForm">
    <c-card title="기본정보" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline>
          <c-btn
            v-if="!disabled"
            :url="updateUrl"
            :isSubmit="isSave"
            :param="tabParam"
            mappingType="PUT"
            label="저장"
            icon="save"
            @beforeAction="saveAccident"
            @btnCallback="saveCallback" 
          />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :editable="editable"
            :disabled="disabled"
            label="보고일"
            name="reportDate"
            v-model="tabParam.reportDate"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="발생원인"
            name="occurrenceCause"
            v-model="tabParam.occurrenceCause">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            :rows="2"
            label="사고피해"
            name="accidentDamageHistory"
            v-model="tabParam.accidentDamageHistory">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            label="비상조치사항"
            :rows="2"
            name="emergencyMeasures"
            v-model="tabParam.emergencyMeasures">
          </c-textarea>
        </div>
      </template>
    </c-card>
    <div class="col-lg-12 col-md-12 col-sm-12">
      <c-table
          ref="table"
          title="사고자 인적사항 목록"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="tabParam.accidentVictimModelList"
          :filtering="false"
          :columnSetting="false"
          :checkClickFlag="false"
          :usePaging="false"
          :editable="editable && !disabled"
          selection="multiple"
          rowKey="victimId"
        >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline> 
            <c-btn v-if="editable && !disabled" :showLoading="false" label="내부직원 추가" icon="add" @btnClicked="add" />
            <c-btn v-if="editable && !disabled" :showLoading="false" label="외부직원 추가" icon="add" @btnClicked="addRow" />
            <c-btn v-if="editable && !disabled && tabParam.accidentVictimModelList.length > 0" :showLoading="false"  label="제외" icon="remove" @btnClicked="remove" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name ==='victimName'">
            <c-text-column
              v-if="props.row['insideOutsideTypeCd'] === 'N'"
              :editable="editable"
              :props="props"
              :col="col"
              :disabled="disabled"
              v-model="props.row['victimName']"
              @datachange="datachange(props)"
            />
            <span v-else>
              {{props.row['victimName']}}
            </span>
          </template>
          <template v-if="col.name ==='birthDate'">
            <c-datepicker
              v-if="props.row['insideOutsideTypeCd'] === 'N'"
              class="tableDatepicker"
              :editable="editable"
              :disabled="disabled"
              v-model="props.row['birthDate']"
              @datachange="datachange(props)"
            />
            <span v-else>
              {{props.row['birthDate']}}
            </span>
          </template>
          <template v-if="col.name ==='career'">
            <c-text-column
              v-if="props.row['insideOutsideTypeCd'] === 'N'"
              :editable="editable"
              :props="props"
              :col="col"
              :disabled="disabled"
              v-model="props.row['career']"
              @datachange="datachange(props)"
            />
            <span v-else>
              {{props.row['career']}}
            </span>
          </template>
        </template>
      </c-table>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-card title="물적피해" class="cardClassDetailForm">
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-text
                :editable="editable"
                :disabled="disabled"
                label="손실내역"
                name="materialLossHistory"
                v-model="tabParam.materialLossHistory">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-text
                :suffix="$language('원')"
                :editable="editable"
                :disabled="disabled"
                type="number"
                label="손실금액"
                name="materialLossAmount"
                v-model="tabParam.materialLossAmount">
              </c-text>
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-card title="환경피해" class="cardClassDetailForm">
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-text
                :editable="editable"
                :disabled="disabled"
                :range="true"
                label="누출정보"
                name="enviromentLeakInformation"
                v-model="tabParam.enviromentLeakInformation">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-text
                :editable="editable"
                :disabled="disabled"
                :range="true"
                label="영향내역"
                name="enviromentImpactHistory"
                v-model="tabParam.enviromentImpactHistory">
              </c-text>
            </div>
          </template>
        </c-card>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>

import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import { uid } from 'quasar'
export default {
  name: 'process-accident-reception-result',

  props: {
    popupParam: {
      type: Object,
      default: () => ({
        stepCd: '',
      }),
    },
    tabParam: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
        investigationFlag: '',
        investigationDate: [],
        investigationPlan: '',
        reportDate: '',
        occurrenceCause: '',
        accidentDamageHistory: '',
        emergencyMeasures: '',
        materialLossHistory: '',
        materialLossAmount: '',
        enviromentLeakInformation: '',
        enviromentImpactHistory: '',
        accidentStatusCd: '',
        deleteAccidentVictimModelList: [],
        accidentVictimModelList: [],
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'victimName',
            field: 'victimName',
            label: '이름',
            style: 'width:10%',
            type: 'custom',
            align: 'center',
            sortable: false
          },
          {
            name: 'birthDate',
            field: 'birthDate',
            label: '생년월일',
            style: 'width:10%',
            align: 'center',
            type: 'custom',
            sortable: false
          },
          {
            name: 'career',
            field: 'career',
            label: '직급',
            style: 'width:10%',
            align: 'center',
            type: 'custom',
            sortable: false
          },
          // {
          //   name: 'insideOutsideTypeName',
          //   field: 'insideOutsideTypeName',
          //   label: '내/외부',
          //   align: 'center',
          //   style: 'width:50px',
          //   sortable: false,
          // },
          {
            name: 'degreeDamage',
            field: 'degreeDamage',
            label: '피해상해정도',
            style: 'width:20%',
            type: "textarea",
            align: 'left',
            sortable: false
          },
          {
            name: 'expectTreatmentDate',
            field: 'expectTreatmentDate',
            label: '예상치료기간',
            type: "date",
            range: true,
            style: 'width:15%',
            align: 'center',
            sortable: false
          },
          {
            name: 'actionMatters',
            field: 'actionMatters',
            label: '조치사항',
            style: 'width:20%',
            type: "textarea",
            align: 'left',
            sortable: false
          },
        ],
        data: [],
        height: '300px'
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      editable: true,
      searchUrl: '',
      updateUrl: 'transactionConfig.sop.iim.accident.process.investigation.update.url',
      isSave: false,
    };
  },
  computed: {
    disabled() {
      return this.editable && Boolean(this.popupParam.stepCd) && (this.popupParam.stepCd === 'ISPC000005') || this.tabParam.investigationFlag === 'N' 
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      this.updateUrl = transactionConfig.sop.iim.accident.process.investigation.update.url;
      this.getUrl = selectConfig.sop.iim.accident.process.get.url;
      // list setting
      this.grid.columns.splice(3, 0, {
        setHeader: true,
        name: 'interspersedFlag',
        field: 'interspersedFlag',
        label: '산재여부',
        align: 'center',
        style: 'width:12%',
        type: 'select',
        comboItems: [
          { code: 'Y', codeName: this.$language('산재') },
          { code: 'N', codeName: this.$language('해당없음') },
        ],
        sortable: false,
      },)
    },
    getDetail() {
      if (this.tabParam.iimAccidentId) {
        this.$http.url = this.$format(this.getUrl, this.tabParam.iimAccidentId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.tabParam.accidentVictimModelList = this.$_.clone(_result.data.accidentVictimModelList);
          this.$_.forEach(this.tabParam.accidentVictimModelList, _item => {
            if (_item.expectTreatmentDate) {
              _item.expectTreatmentDate = _item.expectTreatmentDate.split(',')
            }
          })
        },);
      }
    },
    add() {
      this.popupOptions.title = '사고자 검색';
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.tabParam.accidentVictimModelList, {
            victimUserId: _item.userId,
          });
          if (index === -1) {
            this.tabParam.accidentVictimModelList.splice(0, 0, {
              iimAccidentId: this.tabParam.iimAccidentId,
              victimId: uid(),
              victimUserId: _item.userId,
              victimName: _item.userName,
              birthDate: _item.birthDate,
              insideOutsideTypeName: '내부',
              insideOutsideTypeCd: 'Y',
              interspersedFlag: null,
              age: '',
              career: _item.spotName,
              degreeDamage: '',
              expectTreatmentDate: [],
              actionMatters: '',
              editFlag: 'C'
            })
          }
        })
      }
    },
    addRow() {
      this.tabParam.accidentVictimModelList.splice(0, 0, {
        iimAccidentId: this.tabParam.iimAccidentId,
        victimId: uid(),
        victimName: '',
        birthDate: '',
        insideOutsideTypeName: '내부',
        insideOutsideTypeCd: 'N',
        age: '',
        career: '',
        degreeDamage: '',
        expectTreatmentDate: [],
        interspersedFlag: null,
        actionMatters: '',
        editFlag: 'C'
      })
    },
    remove() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (!this.tabParam.deleteAccidentVictimModelList) {
              this.tabParam.deleteAccidentVictimModelList = []
          }
          if (this.$_.findIndex(this.tabParam.deleteAccidentVictimModelList, { victimId: item.victimId }) === -1
          && item.editFlag !== 'C') {
            this.tabParam.deleteAccidentVictimModelList.push(item)
          }
          this.tabParam.accidentVictimModelList = this.$_.reject(this.tabParam.accidentVictimModelList, { victimId: item.victimId })
        })
      }
    },
    saveAccident() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              if (this.tabParam.investigationDate) {
                this.tabParam.investigationDate = this.tabParam.investigationDate.join();
              }
              this.$_.forEach(this.tabParam.accidentVictimModelList, _item => {
                if (_item.expectTreatmentDate) _item.expectTreatmentDate = _item.expectTreatmentDate.join();
              })
              this.$_.forEach(this.tabParam.deleteAccidentVictimModelList, _item => {
                if (_item.expectTreatmentDate) _item.expectTreatmentDate = _item.expectTreatmentDate.join();
              })
              // if (this.tabParam.investigationFlag === 'Y') {
              //   this.tabParam.accidentStatusCd = 'ISPC000004'
              // } else {
              //   this.tabParam.accidentStatusCd = 'ISPC000002'
              // }
              this.tabParam.regUserId = this.$store.getters.user.userId
              this.tabParam.chgUserId = this.$store.getters.user.userId
              
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.tabParam.investigationDate) {
        this.tabParam.investigationDate = this.tabParam.investigationDate.split(',');
      }
      this.getDetail();
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
  }
};
</script>
